import Axios from 'axios';
import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import Swal from 'sweetalert2'; //Pop-Up

import {PATH_HOME} from './App';
import {config} from "./config";

const POLITICAL_LEANING_LIST = ["None", "Left", "Right"];
const GENDER_LIST = ["Male", "Female", "Prefer not to say"];
export const HOLY_DAY_TYPES = {
    "Shabbat": "Shabbat",
    "RoshHashanah": "Rosh Hashanah",
    "Sukkot": "Sukkot",
    "SheminiAtzeret": "Shemini Atzeret",
    "SimchatTorah": "Simchat Torah",
    "Pesach": "Pesach",
    "Shavuot": "Shavuot",
};
const MAX_LENGTHS =
{
    "EMAIL": 50,
    "PASSWORD": 20,
    "NAME": 40, //No more than 40 characters allowed in a first name or last name
    "ADDRESS": 100,
    "ALLERGY": 15,
    "NOTES": 250,
    "CAPACITY": 3,
};
export const DATE_PICKER_YEAR_RANGE = 120;
export const GLOBAL_ERROR_MESSAGES =
{
    "": "",
    EMPTY_REQUEST: "The details were wiped before reaching the server!",
    NULL_STUDENT: "The details were wiped before reaching the server!",
    NULL_HOST: "The details were wiped before reaching the server!",
    NULL_PERSON: "The details were wiped before reaching the server!",
    MISSING_REQUIRED_FIELD: "Missing required (*) field!",
    BLANK_INPUT: "Missing required (*) field!",
    UNKNOWN_ERROR: "An unknown server error occurred! Please try again later.",
    NO_SERVER_CONNECTION: "Couldn't connect to the server! Please try again later.",
}

const requiredTooltip = props => (<Tooltip {...props}>Required</Tooltip>);

export function Capitalize(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function NameListCase(string)
{
    //Capitalize each word
    return string.split(" ").map(word => Capitalize(word)).join(" ");
}

export function HolyDayToString(type)
{
    if (type in HOLY_DAY_TYPES)
        return HOLY_DAY_TYPES[type];

    return type;
}

export function SortNameListAlphabetically(nameList)
{
    nameList.sort((a, b) => (a.text > b.text) ? 1 : -1); 
}

function CreateNameList(list)
{
    let names = [];

    for (let i = 0; i < list.length; ++i)
        names.push({key: i, text: list[i], value: list[i]});

    SortNameListAlphabetically(names);
    return names; 
}

function CreateLowercaseValueUnsortedNameList(list)
{
    let names = [];

    for (let i = 0; i < list.length; ++i)
        names.push({key: i, text: list[i], value: list[i].toLowerCase()});

    return names; 
}

export function GetNameListsFromServer()
{
    return Axios.get(`${config.devServer}/getnamelists`);
}

export async function CreateNameLists()
{
    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
    {
        return {
            allergies: CreateNameList(["Nut Allergy", "Dairy Allergy", "Fish Allergy", "Peanut Allergy"]),
            foodPreferences: CreateNameList(["Vegan", "Vegetarian"]),
            pets: CreateNameList(["Dog", "Cat"]),
        }
    }
    else
    {
        try
        {
            let ret = await GetNameListsFromServer();
            return {
                allergies: CreateNameList(ret.data.allergies),
                foodPreferences: CreateNameList(ret.data.foodPreferences),
                pets: CreateNameList(ret.data.pets),
            }
        }
        catch (error)
        {
            console.log("Couldn't get name lists from the server: " + error);
        }
    }
}

export async function CreateTotalFoodRestrictionNameList()
{
    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
    {
        return CreateNameList(["Nut Allergy", "Dairy Allergy", "Fish Allergy", "Peanut Allergy", "Vegan", "Vegetarian"]);
    }
    else
    {
        try
        {
            //Combine allergies and food preferences into one list
            let ret = await GetNameListsFromServer();
            let foodRestrictions = ret.data.allergies.concat(ret.data.foodPreferences);
            return CreateNameList(foodRestrictions);
        }
        catch (error)
        {
            console.log("Couldn't get name lists from the server: " + error);
        }
    }
}

export function CreatePoliticalLeaningList()
{
    return CreateLowercaseValueUnsortedNameList(POLITICAL_LEANING_LIST);
}

export function CreateGenderList()
{
    let list = CreateLowercaseValueUnsortedNameList(GENDER_LIST);
    list[list.length - 1].value = "none"; //Change "Prefer not to say"'s actual value to "none"
    return list;
}

export async function CreateDatabaseLocationList()
{
    let locationList = [];

    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
    {
        locationList = [
            {
                collection: process.env.REACT_APP_TEST_COLLECTION || "MISSING_ENV_VAR",
                community: process.env.REACT_APP_TEST_COMMUNITY || "MISSING_ENV_VAR",
                city: "Hamilton",
                country: "Canada",
                countryCode: "+1",
                address: "McMaster University, 1280 Main Street West, Hamilton",
                coords: {lat: 43.260879, lng: -79.9192254},
                guestType: "student",
                emailAddress: "test1@gmail.com",
                maintainerName: "McMaster Maintainer",
                maintainerContact: "+1 905-123-4567",
                features: {
                    MealApproval: false,
                    GuestApproval: false,
                    HostApproval: false,
                    NewDateAddedMessage: true,
                    UnallocatedMessage: true,
                    Distance: false,
                    PoliticalLeaning: false,
                    GuestGenderRequest: false,
                    HostGenderRequest: true,
                    GuestAgeRequest: false,
                    HostAgeRequest: false,
                    GuestFriendRequest: "name",
                    HostGuestRequest: true,
                    MinGuestAge: 0,
                    MaxGuestAge: 0
                }
            },
            {
                collection: process.env.REACT_APP_TEST_COLLECTION_2 || "MISSING_ENV_VAR",
                community: process.env.REACT_APP_TEST_COMMUNITY_2 || "MISSING_ENV_VAR",
                city: "Jerusalem",
                country: "Israel",
                countryCode: "+972",
                address: "Baka, Jerusalem",
                coords: {lat: 31.759335, lng: 35.22078700000001},
                guestType: "guest",
                emailAddress: "test2@gmail.com",
                maintainerName: "JLIC Maintainer",
                maintainerContact: "+972 02-123-4567",
                features: {
                    MealApproval: true,
                    GuestApproval: true,
                    HostApproval: true,
                    NewDateAddedMessage: false,
                    UnallocatedMessage: false,
                    Distance: true,
                    PoliticalLeaning: true,
                    GuestGenderRequest: false,
                    HostGenderRequest: true,
                    GuestAgeRequest: false,
                    HostAgeRequest: true,
                    GuestFriendRequest: "email",
                    HostGuestRequest: false,
                    MinGuestAge: 20,
                    MaxGuestAge: 29
                }
            }
        ];
    }
    else
    {
        try
        {
            let res = await Axios.get(`${config.devServer}/getlocationlist`);
            locationList = res.data.locationList;
        }
        catch (error)
        {
            console.log("Couldn't get location list from the server: " + error);
            return null;
        }
    }

    return locationList
}

export async function CreateStudentNameList(locationDetails)
{
    let names = [];
    let studentList = await GetListOfStudentNames(locationDetails);

    for (let i = 0; i < studentList.length; ++i)
        names.push({key: i, text: studentList[i].name, value: studentList[i].email});

    SortNameListAlphabetically(names);
    return names; 
}

export async function GetListOfStudentNames(locationDetails)
{
    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
        return [{name: "Max Greenberg", email: "maxbgreenberg@gmail.com"}, {name: "Josh Arbess", email: "josh.arbess@gmail.com"}]; //Placeholders
    else
    {
        let res = await Axios.post(`${config.devServer}/getstudentfullnames`,
                                  {community: locationDetails.community, database: locationDetails.collection});
        return res.data.studentNameList;
    }
}

export async function GetMaintainerDashboardInfo(password, locationDetails)
{
    let dates, prevDates, studentList, hostList, weekStudents, weekHosts, prevWeekHosts;
    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
    {
        //Placeholders
        dates = [{date: "2020-12-24", type: "Shabbat", meal: "dinner", allocated: true, approved: true},
                 {date: "2020-12-25", type: "Shabbat", meal: "lunch", allocated: false, approved: false}];
        prevDates = [{date: "2020-12-17", type: "Shabbat", meal: "dinner", allocated: true, approved: true},
                     {date: "2020-12-18", type: "Shabbat", meal: "lunch", allocated: true, approved: true}];

        studentList = [
            {email: "maxbgreenberg@gmail.com", firstName: "Max", lastName: "Greenberg", gender: "male", birthday: "1996-03-07", phone: "289-123-4567", maxTravelTime: 5, vaccinated: false, unvaccinatedComfort: true, allergies: ["nut allergy"], foodPreferences: ["vegan", "vegetarian"], animalFear: ["dog", "cat"], poliLeaning: "none", dating: null, peopleHated: ["josh.arbess@gmail.com"], notes: "", weeks: ["2020-12-17"], approved: true, graduated: false},
            {email: "josh.arbess@gmail.com", firstName: "Josh", lastName: "Arbess", gender: "male", birthday: "1999-09-08", phone: "647-123-4567", maxTravelTime: 15, vaccinated: true, unvaccinatedComfort: false,  allergies: ["dairy allergy", "peanut allergy"], foodPreferences: [], animalFear: [], poliLeaning: "right", dating: {name: "His Wife", email: "wifemeail@gmail.com", gender: "female"}, peopleHated: [], notes: "I like to eat!", weeks: [{date: "2020-12-17", meal: "dinner", community: "Community Name", guests: 5, host: "shaina@chabadhamilton.com"}], approved: false, graduated: false},
            {email: "grad.student@gmail.com", firstName: "Former", lastName: "Student", gender: "male", birthday: "1998-12-01", phone: "905-123-4567", maxTravelTime: 35, vaccinated: true, unvaccinatedComfort: true, allergies: [], foodPreferences: [], animalFear: [], poliLeaning: "none", dating: null, notes: "",  peopleHated: [], weeks: [], approved: true, graduated: true},
        ];
        hostList = [
            {email: "shaina@chabadhamilton.com", name: "Rosenfeld", gender: "none", phone: "905-546-7458", address: "98 Dromore Crescent", vaccinated: true, unvaccinatedComfort: false,  unallocatedEmail: false, pets: [], goodForAllergy: [], eatTime: "", poliLeaning: "none", hatedStudents: [], notes: "", weeks: ["2020-12-18"], approved: true},
            {email: "pearlgasner@gmail.com", name: "Pearl Gasner", single: true, gender: "female", phone: "647-801-4574", address: "100 Beddoe Drive, Unit #2", vaccinated: false, unvaccinatedComfort: true, unallocatedEmail: true, pets: ["dog, cat"], goodForAllergy: ["peanut allergy", "pescatarian"], eatTime: "12:30", poliLeaning: "left", hatedStudents: ["maxbgreenberg@gmail.com"], notes: "Please bring a friend!", weeks: [{date: "2020-12-17",  meal: "dinner", community: "Community Name"}], approved: false},
        ];

        weekStudents = {
            "2020-12-24":
            {
                "dinner": [
                    {name: "Max Greenberg", email: "maxbgreenberg@gmail.com", friends: ["josh.arbess@gmail.com", "josh.arbess@gmail.com"], maleGuests: 0, femaleGuests: 2, guestMinAge: 0, guestMaxAge: 0, guestAllergies: []},
                    {name: "Josh Arbess", email: "josh.arbess@gmail.com", friends: [], maleGuests: 1, femaleGuests: 0, guestMinAge: 18, guestMaxAge: 25, guestAllergies: []},
                ]
            },
            "2020-12-25":
            {
                "lunch": [],
            },
        };
        weekHosts = {
            "2020-12-24":
            {
                "dinner": [
                    {name: "Rosenfeld", email: "shaina@chabadhamilton.com", capacity: 8, genderPref: "", minAge: 0, maxAge: 0, badForAllergy: [], requests: ["fake", "maxbgreenberg@gmail.com"], hosting: ["maxbgreenberg@gmail.com", "josh.arbess@gmail.com"]},
                    {name: "Gasner", email: "pearlgasner@gmail.com", capacity: 6, genderPref: "male", minAge: 18, maxAge: 25, badForAllergy: [], requests: [], hosting: ["maxbgreenberg@gmail.com", "josh.arbess@gmail.com"]},
                ]
            },
            "2020-12-25":
            {
                "lunch": [],
            },
        };
        prevWeekHosts = {
            "2020-12-17": weekHosts["2020-12-24"],
            "2020-12-18": weekHosts["2020-12-25"],
        };
    }
    else
    {
        try
        {
            let res = await Axios.post(`${config.devServer}/getmaintainerdashboardinfo`,
                                       {password: password, community: locationDetails.community, database: locationDetails.collection});
            ({dates, prevDates, studentList, hostList, weekStudents, weekHosts, prevWeekHosts} = res.data);
        }
        catch (error)
        {
            console.log(error);
            return null;
        }
    }

    studentList.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1);
    hostList.sort((a, b) => (a.name > b.name) ? 1 : -1);
    return {dates, prevDates, studentList, hostList, weekStudents, weekHosts, prevWeekHosts};
}

export async function GetUpcomingDateNameList(locationDetails)
{
    if (process.env.REACT_APP_DUMMY_FRONT_END_DATA === "true") //Development
        return [{date: "2021-10-09", meal: "lunch"}, {date: "2021-10-16", meal: "dinner"}, {date: "2021-10-23", meal: "lunch"}];
    else
    {
        let res = await Axios.post(`${config.devServer}/getupcomingdates`,
                                  {community: locationDetails.community, database: locationDetails.collection});
        return res.data.dates;
    }
}

export function GetFeatureValue(locationDetails, feature)
{
    return locationDetails.features[feature];
}

export function IsFeatureEnabled(locationDetails, feature)
{
    return GetFeatureValue(locationDetails, feature);
}

export function IsFeatureSetTo(locationDetails, feature, value)
{
    return GetFeatureValue(locationDetails, feature) === value;
}

export function StudentsHostsHaveProximityChecking(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "Distance");
}

export function StudentsHostsHavePoliticalLeaning(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "PoliticalLeaning");
}

export function HostsGetNewDatesEmails(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "NewDateAddedMessage");
}

export function HostsGetUnallocatedEmails(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "UnallocatedMessage");
}

export function MealsNeedApproval(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "MealApproval");
}

export function GuestsNeedApproval(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "GuestApproval");
}

export function HostsNeedApproval(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "HostApproval");
}

export function HostsHaveGenderRequest(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "HostGenderRequest");
}

export function HostsHaveAgeRequest(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "HostAgeRequest");
}

export function HostsCanRequestGuests(locationDetails)
{
    return IsFeatureEnabled(locationDetails, "HostGuestRequest");
}

export function GetDefaultMinAge(locationDetails)
{
    return GetFeatureValue(locationDetails, "MinGuestAge");
}

export function GetDefaultMaxAge(locationDetails)
{
    return GetFeatureValue(locationDetails, "MaxGuestAge");
}

export function GuestsCanRequestFriendsByName(locationDetails)
{
    return IsFeatureSetTo(locationDetails, "GuestFriendRequest", "name");
}

export function GuestsCanRequestFriendsByEmail(locationDetails)
{
    return IsFeatureSetTo(locationDetails, "GuestFriendRequest", "email");
}

export function ExtractCountryCityCommunity(locationDetails)
{
    let country = locationDetails.country;
    let city = locationDetails.city;
    let community = locationDetails.community;
    return {country, city, community};
}

export function GetMaxTextLength(symbol)
{
    if (symbol in MAX_LENGTHS)
        return MAX_LENGTHS[symbol];

    return Number.MAX_SAFE_INTEGER;
}

export function ValidateEmail(email)
{
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
}

export function ValidateName(name)
{
    const pattern = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u);
    return pattern.test(name);
}

export function ValidateAddress(address)
{
    //Should have a number to try to at least somewhat enforce exact addresses
    return address.match(/.*\d.*/);
}

export function ProcessTextInput(e, symbol, trim)
{
    let maxLength = GetMaxTextLength(symbol);
    let text = e.target.value.substring(0, maxLength);

    if (symbol === "NAME" && text.length > 0)
        text = text[0].toUpperCase() + text.slice(1); //Force first character to always be uppercase for a first or last name

    if (trim)
        text = text.trim();

    return text;
}

/**
 * Gets a set of matches between a student's food restrictions list and a host's food restrictions list.
 * @param {Array<string>} studentList - The list of food restrictions of the student.
 * @param {Array<string>} hostList - The list of food restrictions of the host.
 * @returns {Set<string>} A set of matches that's in the host list and also in the student list.
 */
export function GetFoodRestrictionMatches(studentList, hostList)
{
    const relatedFoodPreferences = new Set(["vegetarian", "vegan", "pescatarian"]);
    let matches = new Set();

    //Remove the suffixes from each item in the list
    let replacementFunc = (item) => item.toLowerCase().replace(" allergy", "").replace("-free").replace("-sensitive");
    let studListNoAllergy = studentList.map(replacementFunc);
    let hostListNoAllergy = hostList.map(replacementFunc);

    //Check for exact matches
    for (let i = 0; i < hostListNoAllergy.length; ++i) //Only need to loop through the host's list
    {
        if (studListNoAllergy.includes(hostListNoAllergy[i]))
            matches.add(hostList[i]); //Add the full allergy name
    }

    //Check for related food preferences
    for (let i = 0; i < hostListNoAllergy.length; ++i)
    {
        if (relatedFoodPreferences.has(hostListNoAllergy[i])) //Is special food preference
        {
            for (let list1Pref of studListNoAllergy)
            {
                if (relatedFoodPreferences.has(list1Pref)) //Is also special food preference
                {
                    matches.add(hostList[i]); //Add the full allergy name
                    break;
                }
            }
        }
    }

    return matches;
}

export function CalculateAge(birthday)
{
    let today = new Date();
    let birthDate = new Date(birthday);
    let age = today.getFullYear() - birthDate.getFullYear();
    let month = today.getMonth() - birthDate.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate()))
        age--;

    return age;
}

export function CalculateDaysSince(date)
{
    //Get the current date
    let today = new Date();
    let todayDay = today.getDate();
    let todayMonth = today.getMonth();
    let todayYear = today.getFullYear();

    //Get the date of the event (use UTC to avoid MOST timezone issues)
    let dateObj = new Date(date);
    let dateDay = dateObj.getUTCDate();
    let dateMonth = dateObj.getUTCMonth();
    let dateYear = dateObj.getUTCFullYear();

    //Calculate the difference in days
    let days = todayDay - dateDay;
    let months = todayMonth - dateMonth;
    let years = todayYear - dateYear;

    if (days < 0)
    {
        months--;
        days += 30;
    }

    if (months < 0)
    {
        years--;
        months += 12;
    }

    //Return number of days
    return days + months * 30 + years * 365;
}

/**
 * Calculates the walking distance between two sets of coordinates.
 * @param {Object} coordinates1 - The first set of coordinates.
 * @param {Object} coordinates2 - The second set of coordinates.
 * @returns {Number} The distance between the two sets of coordinates in metres.
 */
export async function CalculateWalkingDistance(coordinates1, coordinates2)
{
    const DIST_CALC_API_KEY = process.env.REACT_APP_DIST_CALC_API_KEY || "";
    let url = "https://api.tomtom.com/routing/1/calculateRoute/"; //TomTom API endpoint

    //Verify the needed data is present
    if (!(coordinates1 && coordinates2)
    || !("lat" in coordinates1 && "lng" in coordinates1 && "lat" in coordinates2 && "lng" in coordinates2)
    || coordinates1.lat === 0 || coordinates1.lng === 0
    || coordinates2.lat === 0 || coordinates2.lng === 0)
        return -1;

    if (DIST_CALC_API_KEY === "")
    {
        console.log("No API key provided for distance calculation.");
        return -1;
    }

    //Send the request to the API
    try
    {
        let coords = `${coordinates1.lat},${coordinates1.lng}:${coordinates2.lat},${coordinates2.lng}`;
        let response = await Axios.get(`${url}${coords}/json?key=${DIST_CALC_API_KEY}&travelMode=pedestrian`);
        let data = response.data;

        if ("routes" in data && data.routes.length > 0)
            return parseFloat(data.routes[0].summary.lengthInMeters);

        throw new Error(`No routes found between ${coordinates1} and ${coordinates2}.`);
    }
    catch (error)
    {
        console.log(`Error calculating walking distance between ${JSON.stringify(coordinates1)} and ${JSON.stringify(coordinates2)}: ${error}`);
        return -1;
    }
}

/**
 * Updates the cached distance between a student and a host.
 * @param {Object} student - The student object.
 * @param {Object} host - The host object.
 */
export async function UpdateDistanceToHost(student, host)
{
    if (!student || !host)
        return;

    if (!student.distance)
        student.distance = {}; //Create the distance cache if it doesn't exist

    if (!(host.email in student.distance) //Only update if the distance hasn't been cached yet
    && student.coords && host.coords)
    {
        //Cache the distance between the student and the host
        let attempts = 0;
        do
        {
            student.distance[host.email] = await CalculateWalkingDistance(student.coords, host.coords);
            if (student.distance[host.email] < 0)
                await new Promise(r => setTimeout(r, 400)); //Wait 400 ms before trying again
        }
        while (student.distance[host.email] < 0 && attempts < 5); //Loop until a successful response is received
    }
}

export function ErrorHasResponse(error)
{
    if (error === null || error === undefined || error.response === null || error.response === undefined)//Must be checked before
        return false;

    return "data" in error.response && "errorMsg" in error.response.data;
}

export function PageLoading()
{
    return (
        <div className="form-page">
            <h1 className="form-loading">Loading...</h1>
        </div>
    )
}

export function ErrorPage(text)
{
    return (
        <div className="form-page">
            <h1 className="form-loading">{text}</h1>
        </div>
    )
}

export function RequiredTooltip()
{
    return (
        <OverlayTrigger placement="top" overlay={requiredTooltip}>
            <span style={{color: "red"}}>*</span>
        </OverlayTrigger>
    );
}

export function ErrorPopUp(errorMsg)
{
    Swal.fire(
    {
        icon: 'error',
        title: errorMsg,
        cancelButtonText: `Okay`,
        showConfirmButton: false,
        showCancelButton: true,
    });
}

export function CompletedRegistrationPopUp(successString, icon, route)
{
    if (successString.length > 0)
    {
        Swal.fire(
        {
            icon: icon,
            title: successString,
            allowOutsideClick: false,
        }).then(() =>
        {
            if (route)
                window.location.href = route; //Prompt them to fill out the weekly form next - TODO work with React router
        });
    }
    else
    {
        if (route)
            window.location.href = route;
    }
}

export function NoDatesAvailablePopUp(locationDetails)
{
    Swal.fire(
    {
        icon: 'error',
        title: "No dates available!\nTry again next week.",
        allowOutsideClick: false,
    }).then(() =>
    {
        window.location.href = locationDetails.basePath + PATH_HOME; //TODO work with React router
    });
}

export async function SendFormToServer(data, classObj, axiosRoute, successString, locationDetails, successRedirectRoute)
{
    let success;
    data.community = locationDetails.community;
    data.database = locationDetails.collection;

    classObj.setState({showedErrorPopUp: false, formRetVal: null}, async () =>
    {
        await Swal.fire(
        {
            title: 'Submitting, please wait...',
            timer: 30000, //30 seconds
            timerProgressBar: true,
            allowOutsideClick: false,
            showConfirmButton: false,
            showCancelButton: false,
            didOpen: async () =>
            {
                try
                {
                    let icon = "success";
                    let ret = await Axios.post(`${config.devServer}${axiosRoute}`, data);
                    let unapproved = ret.data && "approved" in ret.data && !ret.data.approved;
                    let outOfAgeRange = ret.data && "outOfAgeRange" in ret.data && ret.data.outOfAgeRange;
                    let guestOutOfAgeRange = ret.data && "guestOutOfAgeRange" in ret.data && ret.data.guestOutOfAgeRange;

                    //Handle an unapproved submission
                    if (unapproved)
                    {
                        icon = "info";
                        successString = "While your submission was processed, you are not yet approved for this community.\n"
                                      + "You will receive an email once you are approved.\n"
                                      + `If you're concerned, please contact ${locationDetails.maintainerName} at ${locationDetails.maintainerContact}.`;
                    }

                    //Handle an out-of-age-range submission
                    if (outOfAgeRange)
                    {
                        if (unapproved)
                            successString += "\nAdditionally, you";
                        else
                            successString = "You";
                    }
                    else if (guestOutOfAgeRange)
                    {
                        if (unapproved)
                            successString += "\nAdditionally, your guests";
                        else
                            successString = "Your guests";
                    }

                    if (outOfAgeRange || guestOutOfAgeRange)
                    {
                        icon = "info";
                        successString += " are outside the age range for this community. Satisfying your preferences may be difficult.\n"
                                       + "If this is a problem, feel free to cancel your sign-up.";
                    }

                    //Show the completion pop-up
                    CompletedRegistrationPopUp(successString, icon, successRedirectRoute ? locationDetails.basePath + successRedirectRoute : successRedirectRoute);
                    classObj.setState({formRetVal: ret.data ? ret.data : {}}); //Must always be set to an actual value such as {}
                    success = true;
                }
                catch (error)
                {
                    success = false;
                    async function setClassObjState(newState)
                    {
                        if (classObj.setStateAndWait != null)
                            await classObj.setStateAndWait(newState);
                        else
                            classObj.setState(newState);
                    }

                    if (ErrorHasResponse(error))
                    {
                        await setClassObjState({errorMsg: error.response.data.errorMsg, formRetVal: {}});

                        if (error.response.data.errorMsg === "ACCOUNT_EXISTS")
                            await setClassObjState({invalidEmail: classObj.state.emailInput});

                        if (error.response.data.community)
                            await setClassObjState({errorMsgCommunity: error.response.data.community});

                        if (!classObj.state.showedErrorPopUp)
                            classObj.errorPopUp(error.response.data.errorMsg);
                    }
                    else
                    {
                        await setClassObjState({errorMsg: "NO_SERVER_CONNECTION", formRetVal: {}});
                        if (!classObj.state.showedErrorPopUp)
                            classObj.errorPopUp("NO_SERVER_CONNECTION");
                    }
                }
            },

            //If the timer expires, show an error message
            willClose: () =>
            {
                if (success == null)
                {
                    success = false;
                    classObj.setState({showedErrorPopUp: true, formRetVal: {}}); //So not to show twice if it closed while the request was still active
                    classObj.errorPopUp("NO_SERVER_CONNECTION");
                }
            }
        });
    });

    while (success == null) //Loop until pop-up is closed
        await new Promise(r => setTimeout(r, 100));

    return success;
}

export async function SendQuickDataToServer(data, classObj, axiosRoute, locationDetails)
{
    if (locationDetails)
    {
        data.community = locationDetails.community;
        data.database = locationDetails.collection;
    }

    try
    {
        let ret = await Axios.post(`${config.devServer}${axiosRoute}`, data);
        return ret;
    }
    catch (error)
    {
        let errorCode = (ErrorHasResponse(error)) ? error.response.data.errorMsg : "NO_SERVER_CONNECTION";

        await classObj.setState({errorMsg: errorCode});
        if (!classObj.state.showedErrorPopUp)
            classObj.errorPopUp(errorCode);

        return null;
    }
}

export function ComponentWithRouter(Component)
{
    function ComponentWithRouterProp(props)
    {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }
  
    return ComponentWithRouterProp;
}
