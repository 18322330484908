import React, {Component} from 'react';
import {Button, Form, Card} from "react-bootstrap";

import {GetWeeklyStudentPathByLocation} from './App';
import {Capitalize, CreateNameLists, CreatePoliticalLeaningList,
        ExtractCountryCityCommunity, StudentsHostsHaveProximityChecking, StudentsHostsHavePoliticalLeaning,
        HostsHaveAgeRequest,
        ValidateEmail, ValidateName, ValidateAddress,
        PageLoading, ErrorPopUp, SendFormToServer,
        GLOBAL_ERROR_MESSAGES} from "./Util";
import {AddressField, ExtractAddressLine1} from './subcomponents/AddressField';
import {BirthdayField} from './subcomponents/BirthdayField';
import {DatingField} from './subcomponents/DatingField';
import {FoodDropdown} from './subcomponents/FoodDropdown';
import {GenderField} from './subcomponents/GenderField';
import {NameFields} from './subcomponents/NameFields';
import {NotesField} from './subcomponents/NotesField';
import {EmailField} from './subcomponents/EmailField';
import {NewPasswordField} from './subcomponents/NewPasswordField';
import {PetDropdown} from './subcomponents/PetDropdown';
import {PhoneNumberField, ValidatePhoneNumber, PrettyPrintPhoneNumber} from './subcomponents/PhoneNumberField';
import {PoliLeaningField} from './subcomponents/PoliLeaningField';
import {VaccinationFields} from './subcomponents/VaccinationFields';

import "react-datepicker/dist/react-datepicker.css";
import "./styles/FormPage.css";
import "./styles/RegistrationForm.css";

const COVID_EXISTS = process.env.COVID_EXISTS != null;
const DEFAULT_MAX_WALKING_TIME = 35; //Default to 35 minutes
const ERROR_MESSAGES =
{
    ...GLOBAL_ERROR_MESSAGES,
    INVALID_EMAIL: "Invalid email!",
    INVALID_FNAME: "Invalid first name!",
    INVALID_LNAME: "Invalid last name!",
    INVALID_GENDER: "Invalid gender!",
    INVALID_BIRTHDAY: "Invalid birthday! Make sure the year is correct.",
    INVALID_PHONE: "Invalid phone number!",
    INVALID_ADDRESS: "Invalid address! Make sure to choose your exact address on the map.",
    INVALID_COORDS: "Invalid address coordinates! Choose a new location on the map.",
    INVALID_MAXWALKINGTIME: "Invalid maximum walking time!",
    INVALID_ALLERGIES: "Invalid allergy! Make sure all allergies are free of profanity.",
    INVALID_FOODPREF: "Invalid food preference!",
    INVALID_ANIMALFEAR: "Invalid pet!",
    INVALID_POLILEANING: "Invalid political leaning!",
    INVALID_VACCINATED: "Invalid vaccination status!",
    INVALID_UNVACCINATEDHOSTCOMFORT: "Invalid host comfort vaccination status!",
    MISMATCHED_PASSWORDS: "Passwords don't match!",
    DUPLICATE_EMAIL: "Both emails cannot be the same!",
    ACCOUNT_EXISTS: "An account with that email already exists!",
};


export class NewStudent extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            //Form Inputs
            emailInput: (props.editData)
                ? [props.editData.email, ""]
                : ["", ""],
            passwordInput: (props.editData)
                ? [props.editData.password, ""]
                : ["", ""],
            confirmPasswordInput: (props.editData)
                ? [props.editData.password, ""]
                : ["", ""],
            firstNameInput: (props.editData)
                ? [props.editData.firstName, ""]
                : ["", ""],
            lastNameInput: (props.editData)
                ? [props.editData.lastName, ""]
                : ["", ""],
            genderInput: (props.editData)
                ? [props.editData.gender, ""]
                : ["", ""],
            birthdayInput: (props.editData)
                ? [props.editData.birthday, ""]
                : ["", ""],
            vaccinatedInput: COVID_EXISTS
                ? (
                    (props.editData)
                    ? [props.editData.vaccinated, ""]
                    : ["", ""]
                )
                : [true, true],
            vaccineComfortInput: COVID_EXISTS
                ? (
                    (props.editData)
                    ? [props.editData.vaccineComfort, ""]
                    : ["", ""]
                )
                : [true, true],
            phoneNumberInput: (props.editData)
                ? [(!props.editData.phone.startsWith("+")) ? `${props.locationDetails.countryCode} ${props.editData.phone}` : props.editData.phone, ""]
                : ["", ""],
            addressLine1Input: (props.editData)
                ? [ExtractAddressLine1(props.editData.address), ""]
                : ["", ""],
            addressCoords: (props.editData)
                ? [(props.editData.coords) ? props.editData.coords : null, null]
                : [null, null],
            maxTravelTimeInput: (props.editData)
                ? [props.editData.maxTravelTime, DEFAULT_MAX_WALKING_TIME]
                : [DEFAULT_MAX_WALKING_TIME, DEFAULT_MAX_WALKING_TIME],
            allergyInput: (props.editData)
                ? [props.editData.allergies, []]
                : [[], []],
            foodPreferenceInput: (props.editData)
                ? [props.editData.foodPref, []]
                : [[], []],
            petFearInput: (props.editData)
                ? [props.editData.animalFear, []]
                : [[], []],
            poliLeaningInput: (props.editData)
                ? [props.editData.poliLeaning, "none"]
                : ["none", "none"],
            datingInput: (props.editData)
                ? [props.editData.dating, {}]
                : [{}, {}],
            notesInput: (props.editData)
                ? [props.editData.notes, ""]
                : ["", ""],
            shuffleableKey: [0, 0],

            //Other Data
            coupleInput: props.couple ? props.couple : false,
            allergyNameList: [],
            foodPreferenceNameList: [],
            petNameList: [],
            politicalLeaningList: [],
            showedErrorPopUp: false,
            errorMsg: "",
            errorPerson: 0,
            invalidEmail: "",
            editData: props.editData,
            locationDetails: props.locationDetails,
            loaded: false,
        }
    }

    async componentDidMount()
    {
        let {allergies, foodPreferences, pets} = await CreateNameLists();
        let politicalLeaningList = CreatePoliticalLeaningList();

        this.setState
        ({
            loaded: allergies && foodPreferences && pets && politicalLeaningList,
            allergyNameList: allergies,
            foodPreferenceNameList: foodPreferences,
            petNameList: pets,
            politicalLeaningList: politicalLeaningList,
        });
    }

    isEditing()
    {
        return this.state.editData != null;
    }

    isCoupleRegistration()
    {
        return this.state.coupleInput && !this.isEditing();
    }

    getNumPeople()
    {
        return this.isCoupleRegistration() ? 2 : 1;
    }

    showBirthdayInput()
    {
        return HostsHaveAgeRequest(this.state.locationDetails);
    }

    showAddressInput()
    {
        return StudentsHostsHaveProximityChecking(this.state.locationDetails);
    }

    showPoliticalLeaningInput()
    {
        return StudentsHostsHavePoliticalLeaning(this.state.locationDetails);
    }

    bothPasswordsFilled(i)
    {
        return this.state.passwordInput[i] !== ""
            && this.state.confirmPasswordInput[i] !== "";
    }

    allRequiredFieldsFilled(i)
    {
        if (this.state.firstNameInput[i] === ""
        || this.state.lastNameInput[i] === ""
        || this.state.emailInput[i]=== ""
        || this.state.genderInput[i] === ""
        || (this.state.birthdayInput[i] === "" && this.showBirthdayInput())
        || this.state.vaccinatedInput[i] === ""
        || this.state.vaccineComfortInput[i] === ""
        || this.state.phoneNumberInput[i] === ""
        || (this.state.addressLine1Input[i] === "" && this.showAddressInput())
        || (this.state.addressCoords[i] == null && this.showAddressInput())
        || (this.state.maxTravelTimeInput[i] === "" && this.showAddressInput())
        || this.state.poliLeaningInput[i] === "" //Required to be at least None
        || !this.bothPasswordsFilled(i))
            return false;

        return true;
    }

    validEmail(i)
    {
        return ValidateEmail(this.state.emailInput[i]);
    }

    passwordsMatch(i)
    {
        return this.state.passwordInput[i] === this.state.confirmPasswordInput[i];
    }

    validFirstName(i)
    {
        return ValidateName(this.state.firstNameInput[i]);
    }

    validLastName(i)
    {
        return ValidateName(this.state.lastNameInput[i]);
    }

    validPhoneNumber(i)
    {
        return ValidatePhoneNumber(this.state.phoneNumberInput[i]);
    }

    validAddress(i)
    {
        return !this.showAddressInput() || ValidateAddress(this.state.addressLine1Input[i]);
    }

    coupleHasIdenticalEmails()
    {
        let emailsExisting = {};
        let numPeople = this.getNumPeople();

        for (let i = 0; i < numPeople; ++i)
        {
            if (emailsExisting[this.state.emailInput[i]])
                return true;

            emailsExisting[this.state.emailInput[i]] = true;
        }

        return false;
    }

    getErrorMessage()
    {
        let errorPerson;
        let errorMsg = "";
        let numPeople = this.getNumPeople();

        for (errorPerson = 0; errorPerson < numPeople; ++errorPerson)
        {
            if (!this.allRequiredFieldsFilled(errorPerson))
                errorMsg = "MISSING_REQUIRED_FIELD";
            else if (!this.validEmail(errorPerson))
                errorMsg = "INVALID_EMAIL";
            else if (!this.passwordsMatch(errorPerson))
                errorMsg = "MISMATCHED_PASSWORDS";
            else if (!this.validFirstName(errorPerson))
                errorMsg = "INVALID_FNAME";
            else if (!this.validLastName(errorPerson))
                errorMsg = "INVALID_LNAME";
            else if (!this.validPhoneNumber(errorPerson))
                errorMsg = "INVALID_PHONE";
            else if (!this.validAddress(errorPerson))
                errorMsg = "INVALID_ADDRESS";

            if (errorMsg !== "")
                break;
        }

        if (this.coupleHasIdenticalEmails())
        {
            errorMsg = "DUPLICATE_EMAIL";
            errorPerson = null;
        }

        return {errorMsg, errorPerson};
    }

    isErrorMessage(errorMsg, i)
    {
        return this.state.errorMsg === errorMsg
            && this.state.errorPerson === i;
    }

    emailAlreadyInUse(i)
    {
        return this.state.invalidEmail !== ""
            && this.state.invalidEmail === this.state.emailInput[i];
    }

    randomizeShuffleableKey(j)
    {
        let newKey;
        let currKey = this.state.shuffleableKey;

        do
        {
            newKey = Math.floor(Math.random() * 1000);
        } while (newKey === currKey[j]);

        currKey[j] = newKey;
        this.setState({shuffleableKey: currKey});
    }

    setEmail(i, email)
    {
        let emailInput = this.state.emailInput;
        emailInput[i] = email;
        this.setState({emailInput: emailInput});
    }

    setPassword(i, password)
    {
        let passwordInput = this.state.passwordInput;
        passwordInput[i] = password;
        this.setState({passwordInput: passwordInput});
    }

    setConfirmPassword(i, password)
    {
        let confirmPasswordInput = this.state.confirmPasswordInput;
        confirmPasswordInput[i] = password;
        this.setState({confirmPasswordInput: confirmPasswordInput});
    }

    setFirstName(i, name)
    {
        let firstNameInput = this.state.firstNameInput;
        firstNameInput[i] = name;
        this.setState({firstNameInput: firstNameInput});
    }

    setLastName(i, name)
    {
        let lastNameInput = this.state.lastNameInput;
    
        //If setting the first person, set all blank or identical fields in other lastNameInputs to the same value
        //Currently causes problems and duplicates the name fields
        /*if (i === 0)
        {
            for (let j = 0; j < this.getNumPeople(); ++j)
            {
                if (j === i) //Skip the current person
                    continue;

                if (lastNameInput[j] === "" || lastNameInput[j] === lastNameInput[i]) //Currently identical or blank
                {
                    lastNameInput[j] = name;
                    this.randomizeShuffleableKey(j);
                }
            }
        }*/

        lastNameInput[i] = name;
        this.setState({lastNameInput: lastNameInput});
    }

    setGender(i, gender)
    {
        let genderInput = this.state.genderInput;
        genderInput[i] = gender;

        //If there are only two people and the second gender is blank, set the opposite
        if (this.isCoupleRegistration() && genderInput[1 - i] === "")
        {
            if (gender === "male")
                genderInput[1 - i] = "female";
            else if (gender === "female")
                genderInput[1 - i] = "male";
        
            this.randomizeShuffleableKey(1 - i); //Force re-render for other person
        }

        this.setState({genderInput: genderInput});
    }

    setBirthday(i, input)
    {
        let birthdayInput = this.state.birthdayInput;
        birthdayInput[i] = input;
        this.setState({birthdayInput: birthdayInput});
    }

    setVaccinated(i, isVaccinated)
    {
        let vaccinated = this.state.vaccinatedInput;
        vaccinated[i] = isVaccinated;
        this.setState({vaccinatedInput: vaccinated});
    }

    setVaccineComfort(i, comfortableEatingAtUnvaccinatedHost)
    {
        let vaccineComfort = this.state.vaccineComfortInput;
        vaccineComfort[i] = comfortableEatingAtUnvaccinatedHost;
        this.setState({vaccineComfortInput: vaccineComfort});
    }

    setPhoneNumber(i, input)
    {
        let phoneNumberInput = this.state.phoneNumberInput;
        phoneNumberInput[i] = input;
        this.setState({phoneNumberInput: phoneNumberInput});
    }

    setAddressDetails(i, addressLine1Input, coordsInput)
    {
        let addressLine1 = this.state.addressLine1Input;
        let coords = this.state.addressCoords;

        addressLine1[i] = addressLine1Input;
        coords[i] = coordsInput;

        //Also set the other person's address to the same value if there are only two people
        if (this.isCoupleRegistration())
        {
            addressLine1[1 - i] = addressLine1Input;
            coords[1 - i] = coordsInput;
        }

        this.setState
        ({
            addressLine1Input: addressLine1,
            addressCoords: coords,
        });
    }

    setMaxTravelTime(i, maxTravelTime)
    {
        let maxTravelTimeInput = this.state.maxTravelTimeInput;
        maxTravelTimeInput[i] = maxTravelTime;

        //Also set the other person's max travel time to the same value if there are only two people
        if (this.isCoupleRegistration())
            maxTravelTimeInput[1 - i] = maxTravelTime;

        this.setState({maxTravelTimeInput: maxTravelTimeInput});
    }

    setAllergies(i, allergies)
    {
        let allergyList = this.state.allergyInput;
        allergyList[i] = allergies;
        this.setState({allergyInput: allergyList})
    }

    setAllergyList(allergyList)
    {
        this.setState({allergyNameList: allergyList})
    }

    setFoodPreferences(i, foodPreferences)
    {
        let foodPreferenceList = this.state.foodPreferenceInput;
        foodPreferenceList[i] = foodPreferences;
        this.setState({foodPreferenceInput: foodPreferenceList})
    }

    setFoodPreferenceList(foodPreferenceList)
    {
        this.setState({foodPreferenceNameList: foodPreferenceList})
    }

    setPetFear(i, petFear)
    {
        let petFearList = this.state.petFearInput;
        petFearList[i] = petFear;
        this.setState({petFearInput: petFearList})
    }

    setPoliticalLeaning(i, poliLeaningVal)
    {
        let poliLeaning = this.state.poliLeaningInput;
        poliLeaning[i] = poliLeaningVal;
        this.setState({poliLeaningInput: poliLeaning});
    }

    setDating(i, dating)
    {
        let datingInput = this.state.datingInput;
        datingInput[i] = dating;
        this.setState({datingInput: datingInput});
    }

    setNotes(i, notes)
    {
        let notesInput = this.state.notesInput;
        notesInput[i] = notes;
        this.setState({notesInput: notesInput});
    }

    async submitRegistration(e)
    {
        e.preventDefault(); //Prevent page reload
        let {errorMsg, errorPerson} = this.getErrorMessage();
        const route = (this.isEditing()) ? `/editstudent` : `/registerstudent`;
        const successMessage = (this.isEditing())
            ? "Your details were updated successfully!\nIf you have a host account, your details were updated there as well."
            : "Account created!\nNext, sign up for a meal.";

        if (errorMsg === "") //No error
        {
            let data =
            {
                //Create list for each person
                people: Array.from({length: this.getNumPeople()}, (_, i) =>
                {
                    return {
                        Email: this.state.emailInput[i].toLowerCase(),
                        Password: this.state.passwordInput[i],
                        FName: this.state.firstNameInput[i].trim(), //Not trimmed at input time since then the use can't type multiple names
                        LName: this.state.lastNameInput[i].trim(),
                        Gender: this.state.genderInput[i],
                        Birthday: (this.state.birthdayInput[i] == null) ? "" : this.state.birthdayInput[i],
                        Phone: PrettyPrintPhoneNumber(this.state.phoneNumberInput[i] || ""), //E.g. +1 905-123-4567
                        Address: (this.state.addressLine1Input[i] == null) ? "" : this.state.addressLine1Input[i].trim(),
                        Coords: (this.state.addressCoords[i] == null) ? "" : this.state.addressCoords[i],
                        MaxTravelTime: (this.state.maxTravelTimeInput[i] == null) ? 0 : Number(this.state.maxTravelTimeInput[i]),
                        Allergies: this.state.allergyInput[i],
                        FoodPref: this.state.foodPreferenceInput[i],
                        AnimalFear: this.state.petFearInput[i],
                        Vaccinated: this.state.vaccinatedInput[i],
                        UnvaccinatedHostComfort: this.state.vaccineComfortInput[i],
                        PoliLeaning: this.state.poliLeaningInput[i],
                        Dating: "email" in this.state.datingInput[i] ? this.state.datingInput[i].email : "",
                        Notes: this.state.notesInput[i].trim(),
                    };
                }),
            };

            if (this.isEditing())
            {
                data = data.people[0]; //Only send the first person's data
                data.originalPassword = this.state.editData.password;
            }

            await SendFormToServer(data, this, route, successMessage, this.state.locationDetails, GetWeeklyStudentPathByLocation(this.state.locationDetails));
        }
        else
        {
            this.setState({errorMsg: errorMsg, errorPerson: errorPerson});
            this.errorPopUp(errorMsg, errorPerson);
        }
    }

    errorPopUp(errorSymbol, errorPerson)
    {
        let text = (errorSymbol in ERROR_MESSAGES) ?  ERROR_MESSAGES[errorSymbol] : errorSymbol;

        if (this.isCoupleRegistration() && errorPerson != null)
            text = `Error with ${errorPerson === 0 ? "your" : "your spouse's"} details: ${text}`;

        ErrorPopUp(text);
    }

    printStudent(i)
    {
        let couple = this.isCoupleRegistration();
        let firstPerson = (i === 0);
        let you = firstPerson ? "you" : "your spouse";
        let yours = firstPerson ? "yours" : "your spouse's";
        let Do = firstPerson ? "Do" : "Does";
        let Are = firstPerson ? "Are" : "Is";
        let pluralS = firstPerson ? "" : "s";

        let person =
        <>
            {/*Person Number*/}
            {
                couple &&
                    <h2 className="form-title">
                        {i === 0 ? "Your Details" : "Your Spouse's Details"}
                    </h2>
            }

            {/*Email Input*/}
            <EmailField
                email={this.state.emailInput[i]}
                setParentEmail={this.setEmail.bind(this, i)}
                isInvalid={() => !this.validEmail(i) || this.emailAlreadyInUse(i)}
                readOnly={this.isEditing()}
                idMod={couple ? `${i}` : ""}/>

            {/*Password Input*/}
            <NewPasswordField
                password={this.state.passwordInput[i]}
                confirmPassword={this.state.confirmPasswordInput[i]}
                setParentPassword={this.setPassword.bind(this, i)}
                setParentConfirmPassword={this.setConfirmPassword.bind(this, i)}
                bothPasswordsFilled={this.bothPasswordsFilled.bind(this, i)}
                passwordsMatch={this.passwordsMatch.bind(this, i)}
                isErrorMessage={(error) => this.isErrorMessage(error, i)}
                idMod={couple ? `${i}` : ""}/>

            {/*First & Last Name Input*/}
            <NameFields
                //key={this.state.shuffleableKey[i]} //Force re-render when the key changes
                firstName={this.state.firstNameInput[i]}
                lastName={this.state.lastNameInput[i]}
                firstNameErrorCode="INVALID_FNAME"
                lastNameErrorCode="INVALID_LNAME"
                setParentFirstName={this.setFirstName.bind(this, i)}
                setParentLastName={this.setLastName.bind(this, i)}
                validFirstName={this.validFirstName.bind(this, i)}
                validLastName={this.validLastName.bind(this, i)}
                isErrorMessage={(error) => this.isErrorMessage(error, i)}
                idMod={couple ? `${i}` : ""}/>

            {/*Gender Input*/}
            <GenderField
                key={this.state.shuffleableKey[i]} //Force re-render when the key changes
                gender={this.state.genderInput[i]}
                setParentGender={this.setGender.bind(this, i)}
                isErrorMessage={this.isErrorMessage.bind(this, i)}
                warningCardText={
                    <Card.Text>
                        Certain hosts prefer to have guests of a certain gender. This is used to satisfy that request - not to segregate people.
                        <br/>
                        If you would rather not disclose, you will never be assigned to a gender-specific meal.
                    </Card.Text>
                }
                idMod={couple ? `${i}` : ""} />

            {/*Birthday Input*/}
            {
                this.showBirthdayInput() &&
                    <BirthdayField
                        birthday={this.state.birthdayInput[i]}
                        rationaleText="Used for hosts who prefer guests within a certain age range."
                        setParentBirthday={this.setBirthday.bind(this, i)}
                        isErrorMessage={this.isErrorMessage.bind(this, i)}
                        idMod={couple ? `${i}` : ""} />
            }

            {/*Vaccination Input - COVID Only*/}
            <VaccinationFields
                vaccinated={this.state.vaccinatedInput[i]}
                vaccineComfort={this.state.vaccineComfortInput[i]}
                vaccinatedLabel={`${Are} ${you} fully vaccinated against COVID-19?`}
                vaccineComfortLabel={`${Are} ${you} comfortable eating at unvaccinated hosts?`}
                setParentVaccinated={this.setVaccinated.bind(this, i)}
                setParentVaccineComfort={this.setVaccineComfort.bind(this, i)}
                useDefaultValue={this.isEditing()}
                idMod={couple ? `${i}` : ""} />

            {/*Phone Number Input*/}
            <PhoneNumberField
                currentCountry={this.state.locationDetails.country}
                phoneNumber={this.state.phoneNumberInput[i]}
                setParentPhoneNumber={this.setPhoneNumber.bind(this, i)}
                validPhoneNumber={this.validPhoneNumber.bind(this, i)}
                isErrorMessage={this.isErrorMessage.bind(this, i)}
                idMod={couple ? `${i}` : ""} />

            {/*Address Input with Map*/}
            {
                this.showAddressInput() && (!this.isCoupleRegistration() || i === 0) && //Only show the map for the first person in a couple
                    <AddressField
                        addressLine1={this.state.addressLine1Input[i]}
                        coords={this.state.addressCoords[i]}
                        maxTravelTime={this.state.maxTravelTimeInput[i]}
                        country={this.state.locationDetails.country}
                        defaultCenter={this.state.locationDetails.coords}
                        fieldDesc="Used to match you with hosts who can be reached within your specified walking time. It is not shared with anyone."
                        setParentAddress={this.setAddressDetails.bind(this, i)}
                        setParentMaxTravelTime={this.setMaxTravelTime.bind(this, i)}
                        isErrorMessage={(error) => this.isErrorMessage(error, i)}
                        showMaxTravelTime={true}
                        idMod={couple ? `${i}` : ""} />
            }

            {/*Allergy Input*/}
            <FoodDropdown
                foodSelection={this.state.allergyInput[i]}
                foodNameList={this.state.allergyNameList}
                fieldLabel={`${Do} ${you} have any food allergies?`}
                fieldDesc={`If ${yours} isn't listed here, feel free to add it!`}
                testId="allergy-input"
                allowAdditions={true}
                isAllergyList={true}
                errorCode="INVALID_ALLERGIES"
                setParentFoodSelection={this.setAllergies.bind(this, i)}
                setParentFoodOptions={this.setAllergyList.bind(this, i)}
                isErrorMessage={this.isErrorMessage.bind(this, i)}
                idMod={couple ? `${i}` : ""} />

            {/*Food Preference Input*/}
            <FoodDropdown
                foodSelection={this.state.foodPreferenceInput[i]}
                foodNameList={this.state.foodPreferenceNameList}
                fieldLabel={`${Do} ${you} have any food preferences?`}
                testId="food-pref-input"
                allowAdditions={false}
                isAllergyList={false}
                errorCode="INVALID_FOODPREF"
                setParentFoodSelection={this.setFoodPreferences.bind(this, i)}
                setParentFoodOptions={this.setFoodPreferenceList.bind(this, i)}
                isErrorMessage={this.isErrorMessage.bind(this, i)}
                idMod={couple ? `${i}` : ""} />

            {/*Pet Fear Input*/}
            <PetDropdown
                pets={this.state.petFearInput[i]}
                petNameList={this.state.petNameList}
                fieldLabel={`Are there any pets ${you} dislike${pluralS} being around?`}
                errorCode="INVALID_ANIMALFEAR"
                setParentPets={this.setPetFear.bind(this, i)}
                isErrorMessage={this.isErrorMessage.bind(this, i)}
                idMod={couple ? `${i}` : ""} />

            {/*Political Leaning Input*/}
            {
                this.showPoliticalLeaningInput() &&
                    <PoliLeaningField
                        poliLeaning={this.state.poliLeaningInput[i]}
                        poliLeaningList={this.state.politicalLeaningList}
                        fieldDesc={`If ${you} choose${pluralS}, ${you} will not be matched with hosts who hold the opposite viewpoint.`}
                        setParentPoliLeaning={this.setPoliticalLeaning.bind(this, i)}
                        isErrorMessage={this.isErrorMessage.bind(this, i)}
                        idMod={couple ? `${i}` : ""} />
            }

            {/*Notes Input*/}
            <NotesField
                notes={this.state.notesInput[i]}
                fieldLabel={`${Do} ${you} have any messages for your hosts?`}
                fieldDesc="Anything you write here will be visible to hosts, not just organizers!"
                setNotes={this.setNotes.bind(this, i)}
                idMod={couple ? `${i}` : ""}/>

            {/*Dating Input*/}
            {
                this.isEditing() &&
                    <DatingField
                        datingName={this.state.datingInput[i].name}
                        datingEmail={this.state.datingInput[i].email}
                        setParentDating={this.setDating.bind(this, i)}
                        idMod={couple ? `${i}` : ""} />
            }
        </>;

        //Style the person's details differently if it's a couple
        if (couple)
        {
            return (
                <Card className={!firstPerson ? "mt-5" : ""}>
                    <Card.Body>
                        {person}
                    </Card.Body>
                </Card>
            );
        }
        else
        {
            return person;
        }
    }

    render()
    {
        let couple = this.isCoupleRegistration();
        let {country, city} = ExtractCountryCityCommunity(this.state.locationDetails);
        let guestType = Capitalize(this.state.locationDetails.guestType);
        let title = (this.isEditing()) ? `Edit ${guestType} Details` : `${guestType} ${couple ? "Couple " : ""}Registration`;
        let buttonText = (this.isEditing()) ? "Save Changes" : "Continue";
        let buttonVariant = (this.isEditing()) ? "success" : "primary";

        if (!this.state.loaded)
            return PageLoading();

        return (
            <div className="form-page">
                <h1 className="form-title weekly-student-form-title">{title}</h1>
                <h2 className="form-title weekly-student-form-title">{city}, {country}</h2>
                <div className="mb-3">
                {
                    !this.isEditing() &&
                        <Form.Text>This form is filled out once. A different form is filled out each time you wish to join.</Form.Text>
                }
                </div>

                <Form onSubmit={(e) => this.submitRegistration(e)}>
                    {this.printStudent(0)}
                    {couple && this.printStudent(1)}

                    {/* Submit Button */}
                    <div className="submit-form-button-container mt-2">
                        <Button variant={buttonVariant} className="submit-form-button" type="submit">
                            {buttonText}
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default NewStudent;
