/**
 * This file defines the DateDropdown component.
 * It is used to input a specific date from a list of options.
 */

import React, {Component} from 'react';
import {DropdownButton, Form} from "react-bootstrap";
import {Dropdown} from 'semantic-ui-react';

import {Capitalize, RequiredTooltip} from '../Util';


export class DateDropdown extends Component
{
    /**
     * Represents the DateDropdown component.
     * @constructor
     * @param {Object} props - The props object containing the initial values for the component.
     * @param {string} props.text - The text value for the component.
     * @param {Array} props.dateList - The list of dates for the component.
     * @param {string} props.dateInput - The default date input value for the component.
     * @param {string} props.holyDayInput - The default holy day input value for the component.
     * @param {string} props.mealInput - The default meal input value for the component.
     * @param {Function} props.setParentDateInputs - The function to set the parent date inputs.
     */
    constructor(props)
    {
        super(props);

        this.state =
        {
            text: props.text,
            dateList: props.dateList,
            dateInput: props.dateInput,
            holyDayInput: props.holyDayInput,
            mealInput: props.mealInput,
        }

        this.setParentDateInputs = props.setParentDateInputs;
    }

    /**
     * Sets the date inputs and also updates the parent.
     * @param {string} date - The date input value.
     * @param {string} holyDay - The holy day input value.
     * @param {string} meal - The meal input value.
     */
    setDateInputs(date, holyDay, meal)
    {
        this.setState({dateInput: date, holyDayInput: holyDay, mealInput: meal});
        this.setParentDateInputs(date, holyDay, meal);
    }

    /**
     * Renders the internal data for the date dropdown component.
     * @returns {JSX.Element} The rendered date dropdown component.
     */
    printDateDropdown()
    {
        let dates = [];
        const holyDayStr = this.state.holyDayInput.split(/(?=[A-Z])/).map(Capitalize).join(" "); //Split on capital letters and capitalize the first letter of each word
        const stringFromInput = `${this.state.dateInput} - ${holyDayStr} ${Capitalize(this.state.mealInput)}`;

        //Build the dropdown items
        for (let i = 0; i < this.state.dateList.length; ++i)
        {
            const date = this.state.dateList[i].date;
            const holyDay = this.state.dateList[i].type.split(/(?=[A-Z])/).map(Capitalize).join(" "); //Split on capital letters and capitalize the first letter of each word
            const meal = this.state.dateList[i].meal;
            dates.push(
                <Dropdown.Item key={i} onClick={() => this.setDateInputs(date, holyDay, meal)}>
                    {date} - {holyDay} {Capitalize(meal)}
                </Dropdown.Item>
            );
        }

        //Choose the right dropdown type
        if (dates.length === 0) //No dates available
        {
            return (
                <Form.Text className="mb-1">
                    No dates available.
                </Form.Text>
            );
        }
        else if (dates.length === 1 && this.state.dateInput !== "") //Only one option and it is already selected
        {
            return ( //So make it a read-only field
                <Form.Control
                    required
                    readOnly={true}
                    disabled={true}
                    name="date"
                    value={stringFromInput} //Display the date and meal
                />
            );
        }
        else //Multiple options - probably holiday that week too
        {
            const title = (this.state.dateInput === "") ? "Pick Date" : stringFromInput;

            return (
                <DropdownButton variant={"secondary"} title={title}>
                    {dates}
                </DropdownButton>
            );
        }
    }

    /**
     * Renders the DateDropdown component.
     * @returns {JSX.Element} The rendered DateDropdown component.
     */
    render()
    {
        const required = RequiredTooltip();

        return (
            <Form.Group className="mb-3">
                {
                    this.state.text &&
                        <Form.Label className="mb-2">{this.state.text}{required}</Form.Label>
                }
                <br/>
                {this.printDateDropdown()}
            </Form.Group>
        );
    }
}

export default DateDropdown;
